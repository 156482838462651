<template>
  <div class="aboutus">
    <div class="aboutus_banner">
      <img
        src="https://qxqy-online.sdhckjvip.com/official/banner/banner_52x.png"
        alt=""
      />
    </div>
    <div class="aboutus_container">
      <div class="box aboutus_container_container">
        <div class="aboutus_container_info">
          <img
            ref="boxOneRef"
            src="https://qxqy-online.sdhckjvip.com/OfficialWebsite/Title_AboutUs.png"
            alt=""
          />
          <div :class="{ view_to_bottom: boxOneShow }">
            <div>
              泉心权益是专业的权益卡券资源共享、置换平台。秉承着“待你全心全意”的服务宗旨，力图打造一个符合公序良俗、为用户提供价值的综合性权益服务平台。业务涵盖权益卡券回收、购买与充值、代充三大板块，除支持用户买卖交易外，另提供API接口对接服务及大宗服务、企业定制服务。2022年12月，国家发改委印发《“十四五”扩大内需战略实施方案》，以“2023消费提振年”为主线，统筹开展全国性消费促进活动。随着移动支付和电商平台的快速发展，权益卡券方兴未艾，我们在发展过程中不断进行创新技术、优化管理、提高服务，致力于促进行业市场公平、有序、繁荣发展。
            </div>
            <div>
              当“免费经济”热潮退去，更多的消费者对品牌和服务产生更高归属需求。2020-2025年中国互联网会员经济市场规模呈逐年递增态势，2022年达1861.78亿元，预计2025年将达2175.82亿元。随着各大平台对不同用户群体的深耕细分及付费会员模式的不断创新，权益卡券市场将愈发受到重视。权益卡券行业作为新兴蓝海市场，为消费者带来福利的同时，行业标准尚未完善，对于行业的可持续性发展造成一定影响。在整体发展态势向好的大趋势下，我们致力于打造一个有社会责任感的权益交易平台，持续推进消费环境建设，优化商品和服务供给，积极解决个人信息泄露、预付式消费跑路、“价格刺客”等热点问题，保障消费者正当权益。
            </div>
          </div>
        </div>
        <div class="aboutus_container_phone">
          <img
            ref="boxTwoRef"
            src="https://qxqy-online.sdhckjvip.com/OfficialWebsite/Title_ContactUs.png"
            alt=""
          />
          <div
            class="aboutus_container_phone_con"
            :class="{ view_to_bottom: boxTwoShow }"
          >
            <div class="aboutus_container_phone_box">
              <img src="../../assets/aboutUs/icon_one.png" alt="" />
              <div>微信</div>
              <div class="aboutus_container_phone_box_wx">
                <img
                  src="https://qxqy-online.sdhckjvip.com/OfficialWebsite/wxcode_two.png"
                  alt=""
                />
              </div>
              <div class="aboutus_container_phone_box_wx">
                <img
                  src="https://qxqy-online.sdhckjvip.com/OfficialWebsite/wxcode_one.png"
                  alt=""
                />
              </div>
            </div>
            <div class="aboutus_container_phone_box">
              <img src="../../assets/aboutUs/icon_two.png" alt="" />
              <div>电话</div>
              <div class="aboutus_container_phone_box_call">
                <div>15562541312</div>
                <div>15621871912</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "aboutus",
  data() {
    return {
      currentScroll: 0,
      boxOneShow: false,
      boxTwoShow: false,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handalScroll, true);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handalScroll, true);
  },
  methods: {
    handalScroll() {
      this.currentScroll =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop ||
        0;
      if (this.currentScroll * 2 >= this.$refs.boxOneRef.offsetTop) {
        this.boxOneShow = true;
      }
      if (this.currentScroll * 2 >= this.$refs.boxTwoRef.offsetTop) {
        this.boxTwoShow = true;
      }
    },
  },
};
</script>
<style scoped>
div {
  box-sizing: border-box;
}
.aboutus_banner {
  width: 100%;
  height: 700px;
}
.aboutus_banner img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.aboutus_container {
  width: 100%;
  background-image: url("https://qxqy-online.sdhckjvip.com/official/contact_us_bg@2x.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.aboutus_container_container {
  padding-top: 126px;
}
.aboutus_container_info > div {
  transform: translateY(100px);
  opacity: 0;
}
.aboutus_container_info div {
  width: 100%;
  font-size: 20px;
  font-weight: 500;
  color: #69728c;
  text-indent: 2em;
  line-height: 50px;
}
.aboutus_container_info > div > div:nth-child(2) {
  margin: 24px 0;
}
.aboutus_container_info,
.aboutus_container_phone {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.aboutus_container_info > img,
.aboutus_container_phone > img {
  width: 320px;
  height: 80px;
}
.aboutus_container_phone {
  margin-top: 120px;
  padding-bottom: 100px;
}
.aboutus_container_phone_con {
  width: 100%;
  flex: 1;
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
  transform: translateY(100px);
  opacity: 0;
}
.aboutus_container_phone_box {
  width: 590px;
  background: #fff;
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
}
.aboutus_container_phone_box {
  display: flex;
  align-items: center;
  padding: 26px 40px;
}
.aboutus_container_phone_box > img:nth-child(1) {
  width: 48px;
  height: 48px;
}
.aboutus_container_phone_box > div:nth-child(2) {
  font-size: 28px;
  color: #69728c;
  font-weight: 600;
  margin-left: 16px;
}
.aboutus_container_phone_box_call {
  font-size: 28px;
  color: #292c33;
  font-weight: 500;
  margin-left: 32px;
}
.aboutus_container_phone_box_call > div:nth-child(2) {
  margin-top: 12px;
}
.aboutus_container_phone_box_wx {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 32px;
  font-size: 14px;
  color: #b6b9bf;
  font-weight: 500;
}
.aboutus_container_phone_box_wx img,
.aboutus_container_phone_box_wx img {
  width: 68px;
  height: 68px;
  margin-bottom: 4px;
}
.view_to_bottom {
  transform: translateY(0) !important;
  opacity: 1 !important;
  transition: all 0.5s ease;
}
@media (max-width: 1600px) {
  .aboutus_container_info > div {
    opacity: 1;
    transform: translateY(0px);
  }
  .aboutus_container_phone_con {
    opacity: 1;
    transform: translateY(0px);
  }
}
@media (max-width: 1200px) {
  .aboutus_container_phone_box {
    width: 40%;
    margin-bottom: 40px;
    
  }.aboutus_container_phone_box_wx img{
    width: 136px;
    height: 136px;
    }
  .aboutus_container {
    height: 1400px;
  }
  .aboutus_container_phone_box_call> div {
    font-size: 40px;
  }
    
  .aboutus_container_info > div {
    font-size: 28px;
    opacity: 1;
    transform: translateY(0px);
  }
 
  .aboutus_container_phone_con {
    opacity: 1;
    transform: translateY(0px);
  }
  .aboutus_container_info div {
    font-size: 2.2rem;
  }
  .aboutus_container_info > img,
.aboutus_container_phone > img{
    width: 420px;
    height: 105px;
  }
}
</style>